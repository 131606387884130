import "materialize-css/dist/css/materialize.min.css";
import "materialize-css/dist/js/materialize.min.js";
import './index.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import store from './Store';
import App from './App';
import * as serviceWorker from './serviceWorker';

createRoot(document.getElementById("root")).render(
	<Provider store={store}>
    <App />
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();