import React, { Component } from "react";
import FirstComponent from "./components/FirstComponent";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { connect } from "react-redux";
import { Toaster } from "react-hot-toast";

const router = createBrowserRouter([
	{
		path: "/",
		element: <FirstComponent />
	},
	{
		path: "/customer",
		element: <FirstComponent />
	}
]);

class App extends Component {
  componentWillMount() {

  }

  render() {
    return (
			<div className="container">
				<Toaster position="top-center"/>
				<RouterProvider router={router}/>
			</div>
    );
  }
}

export default connect(null, null)(App);