import "./info.css";
import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Error from "./Error";
import Preloader from "./Preloader";
import { jsonToArray, subscriptionToJson } from "../utils/jsonToArray";
import { getSubscriptionInvoices, refundInvoice } from "../services/endpoints";
import Modal from "./modal/modal";

const Subscription = ({ subscription, error, userToken }) => {
  const userSubscriptionJson = useMemo(() => jsonToArray(subscriptionToJson(subscription)), [subscription]);
  const [spanCols, setSpanCols] = useState(0);
  const [spanColsInvoices, setSpanColsInvoices] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [currentInvoices, setCurrentInvoices] = useState(null);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = async (subscriptionId) => {
    const response = await getSubscriptionInvoices(subscriptionId, userToken)
		if (!response) {
			setCurrentInvoices(null);
			return ;
		}
		const invoices = jsonToArray(response.data, "-");

		if (invoices.length > 0) {
			setSpanColsInvoices(invoices[0].length);
		}

		setIsOpen(true);
		setCurrentInvoices(invoices);
  };

	const handleRefund = async (invoiceId) => {
		await refundInvoice(invoiceId, userToken)
	}

  //return when error is not undefined and is a subscription error
  if (error !== undefined && error.isError && error.type === "subscription") {
    return (
      <div className="to-do-list-container">
        <div className="row">
          <Error
            from={error.from}
            status={error.status}
            message={error.message}
          />
        </div>
      </div>
    );
  }

  //return when subscription is loading
  if (subscription === "loading") {
    return (
      <div className="row center-align">
        <div className="col s4 offset-s4">
          <Preloader />
        </div>
      </div>
    );
  }

  //return when subscription is not empty and has subscriptions
  if (
    !_.isEmpty(userSubscriptionJson) &&
    subscription !== "" &&
    subscription.subscriptions !== undefined &&
    subscription.subscriptions.length > 0
  ) {
    const headers = userSubscriptionJson[0];
    const data = userSubscriptionJson[1];
    //maxCols + 1 because we add a column to show the search button
    return (
      <>
        <table className="subscription-table">
          <thead>
            <tr>
              <th colSpan={spanCols + 1}>Suscripciones</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, indexItem) => (
              <React.Fragment key={`headersRow-${indexItem}`}>
                <tr>
                  <th colSpan={spanCols + 1} className="row-title">
                    {headers[indexItem]}
                  </th>
                </tr>
                {item.map((subItem, indexSubItem) => (
                  <React.Fragment key={`subItem-${indexSubItem}`}>
                    <tr>
                      {headers[indexItem] === "Suscripciones" && (
                        <>
                          {indexSubItem !== 0 ? (
                            <td key={`invoices-${indexSubItem}`}>
                              <span
                                onClick={() => handleOpen(subItem[0])}
                                className="waves-effect waves-light teal lighten-5 teal-text text-darken-4 btn-small"
                              >
                                <i className="small material-icons">search</i>
                              </span>
                            </td>
                          ) : (
                            <th key={`invoices-${indexSubItem}`}>Invoices</th>
                          )}
                        </>
                      )}
                      {subItem.map((value, indexValue) => {
                        if (subItem.length > spanCols) {
                          setSpanCols(subItem.length);
                        }
                        return indexSubItem === 0 ? (
                          <th
                            key={`header-${indexValue}`}
                            className="with-border"
                          >
                            {value}
                          </th>
                        ) : (
                          <td
                            key={`item-${indexValue}`}
                            className="with-border"
                          >
                            {value}
                          </td>
                        );
                      })}
                    </tr>
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>

        <Modal isOpen={isOpen} onClose={handleClose}>
          <table className="subscription-table">
            <thead>
              <tr>
                <th colSpan={spanColsInvoices + 1}>Invoices</th>
              </tr>
            </thead>
            <tbody>
              {currentInvoices !== null &&
                currentInvoices.map((invoice, index) => (
                  <tr key={`invoice-row-${index}`}>
                        <>
                          {index !== 0 ? (
                            <td key={`invoice-action-${index}`} className="center">
                              <button
																onClick={() => handleRefund(invoice[0])}
                                className="waves-effect waves-light teal lighten-5 teal-text text-darken-4 btn-small"
																disabled={invoice[1].toLowerCase() === "refunded"}
                              >
                                <i className="material-icons">send</i>
                              </button>
                            </td>
                          ) : (
                            <th key={`invoices-action-${index}`}>Reembolsar</th>
                          )}
                        </>
                    {invoice.map((value, indexValue) => (
                      <React.Fragment key={`invoice-value-${indexValue}`}>
                        {index === 0 ? <th>{value}</th> : <td>{value}</td>}
                      </React.Fragment>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </Modal>
      </>
    );
  }

  if (!_.isEmpty(userSubscriptionJson) && subscription !== "") {
    return <div>El usuario no tiene suscripciones.</div>;
  }

  return <div></div>;
};

const mapStateToProps = ({ subscription, error }) => {
  return {
    subscription,
    error,
  };
};

export default connect(mapStateToProps, null)(Subscription);
