import React from "react";

// Styles
import modalStyles from "./modal.module.css";

const Modal = ({isOpen, onClose, children, verticalAlign = "center", zIndex = 1300, id = "styled-modal"}) => {

  const handleClose = (event) => {
    const target = event.target;
    if(target.id === id){
      onClose?.();
    }
  };

  if(!isOpen) {return null;}

  return(
    <div style={{zIndex: zIndex}} className={`${modalStyles["modal-styles"]} ${modalStyles[verticalAlign]}`} id={id} onClick={handleClose}>
      {children}
    </div>
  );
};

export default Modal;