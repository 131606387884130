import "../index.css";
import "./ToDoList.css";
import React, { useState } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import UserInfo from "./UserInfo";
import CardList from "./CardList";
import Error from "./Error";

const FirstComponent = ({ fetchToken, fetchCard, error }) => {
	const [formValue, setFormValue] = useState({
		email: "",
		creditCardLast4: ""
	});

	const handleFormSubmit = (event) => {
		event.preventDefault();
		if (formValue.email !== "") fetchToken(formValue.email);
		else if (formValue.creditCardLast4 !== "") fetchCard(formValue.creditCardLast4);
	};

	const handleInputChange = (event) => {
		const { id, value } = event.target;
		setFormValue((prevFormValue) => {
			const newFormValue = { ...prevFormValue, [id]: value };
			if (id === "email") newFormValue.creditCardLast4 = "";
			else if (id === "creditCardLast4") newFormValue.email = "";
			return newFormValue;
		});
	};

	const renderForm = () => (
		<div id="todo-add-form" className="col s10 offset-s1">
			<form onSubmit={handleFormSubmit}>
				<div className="input-field">
					<i className="material-icons prefix">email</i>
					<input
						value={formValue.email}
						onChange={handleInputChange}
						id="email"
						type="email"
						className="validate"
					/>
					<label htmlFor="email">Email de usuario</label>
					<span className="helper-text" data-error="No es un email correcto!" data-success="Correcto!"></span>
				</div>
				<div className="input-field">
					<i className="material-icons prefix">credit_card</i>
					<input
						value={formValue.creditCardLast4}
						onChange={handleInputChange}
						id="creditCardLast4"
						type="number"
						className="validate"
					/>
					<label htmlFor="creditCardLast4">Tarjeta (4 ultimos digitos)</label>
					<span className="helper-text" data-error="No es un numero!" data-success="Correcto!"></span>
				</div>
				<div className="button-field">
					<button className="btn waves-effect waves-light">Enviar<i className="material-icons right">send</i></button>
				</div>
			</form>
			<CardList />
			<UserInfo />
		</div>
	);

	if (error.isError && error.type === "token") {
		return (
			<div>
				{renderForm()}
				<div className="row">
					<Error from={error.from} status={error.status} message={error.message} />
				</div>
			</div>
		);
	}

	return (
		<div>
			{renderForm()}
		</div>
	);
};

const mapStateToProps = ({ error }) => ({
	error
});

export default connect(mapStateToProps, actions)(FirstComponent);
