import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchUser, fetchSubscription } from "../actions";

const Token = ({ email, token_user, fetchUser, fetchSubscription, onSearch }) => {
	const handleSearch = (fn, token_user) => {
		fn(token_user);
		onSearch?.(token_user);
	};

	return (
		<tr>
			<td>{email}</td>
			<td>{token_user}</td>
			<td>
				<span
					onClick={() => handleSearch(fetchUser, token_user)}
					className="waves-effect waves-light teal lighten-5 teal-text text-darken-4 btn-small"
				>
					<i className="small material-icons">search</i>
				</span>
			</td>
			<td>
				<span
					onClick={() => handleSearch(fetchSubscription, token_user)}
					className="waves-effect waves-light teal lighten-5 teal-text text-darken-4 btn-small"
				>
					<i className="small material-icons">search</i>
				</span>
			</td>
		</tr>
	);
};

export default connect(null, { fetchUser, fetchSubscription })(Token);
