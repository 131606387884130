import axios from "axios"
import { apiUrl } from "./common"
import { fetchError } from "../actions"
import store from '../Store';
import toast from "react-hot-toast";

export const getSubscriptionInvoices = async (subscriptionId, authorization) => {
	try {
		const response = await axios.get(`${apiUrl}/invoices?subscription_id=${subscriptionId}`, {
			headers: {
				"content-type": "application/json",
				"Authorization": `token ${authorization}`,
				"platform": "Web",
				"device": "customer service"
			}
		})
		toast.success("Invoices obtenidos con éxito")
		return response.data
	} catch (error) {
		toast.error("Hubo un error al obtener los invoices")
		fetchError(store.dispatch, error, "invoice", "get invoice")
		return null
	}
}

export const refundInvoice = async (invoiceId, authorization) => {
	try {
		const response = await axios.post(`${apiUrl}/invoice/${invoiceId}/refund`, null, {
			headers: {
				"content-type": "application/json",
				"Authorization": `token ${authorization}`,
				"platform": "Web",
				"device": "customer service"
			}
		})
		toast.success(`Invoice ${invoiceId} reembolsado con éxito`)
		return response.data
	} catch (error) {
		toast.error("Hubo un error al reembolsar el invoice")
		fetchError(store.dispatch, error, "invoice", "get invoice")
		return null
	}
}