import { naming } from "../components/naming";

export function jsonToArray(data, howFillEmpty = "-") {
	if(!data) return [];
	
	if(Array.isArray(data)) {
		const parsed = ArrayOfJsonToArray(data);
		return parsed
	};

	if(typeof data !== "object") return data || howFillEmpty;

	let parsedData = {...data};
	parsedData = Object.entries(parsedData);

	let mergedKeys = []
	let mergedValues = []

	parsedData.forEach((item) => {
		let [key, value] = item;
		
		if(value === "") {
			value = howFillEmpty;
		}

		mergedKeys = [...mergedKeys, key];
		mergedValues = [...mergedValues, value];	
	});
	
	mergedValues.forEach((item, index) => {
		if(typeof item === "object" || Array.isArray(item)) {
			mergedValues[index] = jsonToArray(item);
		}
	})

	parsedData = [mergedKeys, mergedValues];

	return parsedData;
}

export const ArrayOfJsonToArray = (data) => {
	if(!data) return []

	if(!Array.isArray(data)) return data;

	let mergedData = []

	data.forEach((item) => {
		mergedData = [...mergedData, jsonToArray(item)];
	});
	
	let noDuplicateKeys = [mergedData[0][0]];

	mergedData.forEach((item) => {
		noDuplicateKeys = [...noDuplicateKeys, item[1]];
	});

	return noDuplicateKeys;
}

export const subscriptionToJson = (subscription) => {
	var resultJson = {};
	Object.keys(subscription).forEach((key) => {
		const value = subscription[key];
		const newKey = naming(key);
		var newValue = value !== null && value !== undefined ? value : "";
		if (typeof newValue === "object") {
			var subResult = {},
				subResultSubscription = [];
			Object.keys(newValue).forEach((subKey) => {
				const subValue = newValue[subKey];
				const newSubKey = naming(subKey);
				var newSubValue =
					subValue !== null && subValue !== undefined ? subValue : "";
				if (typeof newSubValue === "object") {
					var subSubResult = {};
					var newSubValue2 = newSubValue;
					Object.keys(newSubValue2).forEach((subSubKey) => {
						const subSubValue = newSubValue2[subSubKey];
						const newSubSubKey = naming(subSubKey);
						var newSubValue =
							subSubValue !== null && subSubValue !== undefined
								? subSubValue
								: "";
						if (subSubKey === "card")
							newSubValue =
								newSubValue.card_first_six_digits !== undefined
									? newSubValue.card_first_six_digits +
										"***" +
										newSubValue.card_last_four_digits +
										"--" +
										newSubValue.card_month +
										"/" +
										newSubValue.card_year +
										"--" +
										newSubValue.card_type
									: "-";
						else newSubValue = naming(newSubValue.toString());
						subSubResult[newSubSubKey] = newSubValue;
					});
					newSubValue = subSubResult;
				} else newSubValue = newSubValue.toString();
				if (key === "subscriptions") {
					subResultSubscription.push(newSubValue);
					subResult = subResultSubscription;
				} else subResult[newSubKey] = newSubValue;
			});
			newValue = subResult;
		} else newValue = newValue.toString();
		resultJson[newKey] = newValue;
	});
	return resultJson;
};