import "../index.css";
import './info.css';
import "./ToDoList.css";
import React, { useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import * as actions from "../actions";
import Token from "./Token";
import Actions from "./Actions";
import User from "./User";
import Subscription from "./Subscription";
import Preloader from "./Preloader";

const UserInfo = ({ tokens, error, fetchToken }) => {
	const [lastClickedToken, setLastClickedToken] = useState(null);

	const renderTokens = () => {
		const tokenlist = _.map(tokens, (value, key) => {
			return <Token key={key} email={value.email} token_user={value.token} onSearch={setLastClickedToken}/>;
		});
		if (!_.isEmpty(tokenlist)) {
			return (
				<table>
					<thead>
						<tr>
							<th>Email</th>
							<th>Token</th>
							<th>Info</th>
							<th>Suscripción</th>
						</tr>
					</thead>
					<tbody>{tokenlist}</tbody>
				</table>
			);
		}
		return (
			<div className="col s10 offset-s1 center-align">
				<h5>No hay ningun usuario con ese email.</h5>
				<p>Busca con otro email.</p>
			</div>
		);
	};

	if ((error.isError && error.type === "token") || tokens === "") {
		return <div></div>;
	}
	if (tokens === "loading") {
		return (
			<div className="row center-align">
				<div className="col s4 offset-s4">
					<Preloader />
				</div>
			</div>
		);
	}
	return (
		<div className="to-do-list-container">
			<div className="row">
				{renderTokens()}
				<User />
				<Subscription userToken={lastClickedToken}/>
				<Actions />
			</div>
		</div>
	);
};

const mapStateToProps = ({ tokens, user, error }) => {
	return {
		tokens,
		user,
		error,
	};
};

export default connect(mapStateToProps, actions)(UserInfo);
